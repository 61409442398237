.topBar {
  background-color: #e0e0e0;
  height: 50px;
  margin-bottom: 40px;
  padding: 1rem;
}

.imgBestseller {
  height: 13px;
  margin-left: 30px;
  cursor: pointer;
}

.authLogin {
  margin-right: 8px;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  background-color: green;
  border: 1px solid transparent;
  border-radius: 3px;
  float: right;
  cursor: pointer;
  margin-left: auto;
}

.authLogin:hover {
  margin: 8px;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  background-color: red;
  border: 1px solid transparent;
  border-radius: 3px;
  float: right;
  cursor: pointer;
  margin-left: auto;
}

.authLogout {
  margin: 8px 8px 0px 0px;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  background-color: red;
  border: 1px solid transparent;
  border-radius: 3px;
  float: right;
  cursor: pointer;
  margin-left: auto;
}

.authLogout:hover {
  margin: 8px 8px 0px 0px;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  background-color: green;
  border: 1px solid transparent;
  border-radius: 3px;
  float: right;
  cursor: pointer;
  margin-left: auto;
}

.environmentBox {
  margin: 8px 20px 0px 0px;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  background-color: #d9534f;
  border: 1px solid transparent;
  border-radius: 3px;
  float: right;
}
