.MuiDialog-paper {
  width: 500px;
  /* border-radius: 12px; */
}

.MuiDialog-paperScrollPaper {
  display: flex;
  flex-direction: column;
}
/* 
  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;
    font-size: 24px;
  }
  
  .MuiDialogContent-root {
    flex-grow: 1;
  }
  
  .MuiDialogActions-root {
    display: flex;
    justify-content: center;
  }
   */
