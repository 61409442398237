body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'AdelleBSOffice';
  src:
    local('AdelleBSOffice'),
    url(./fonts/AdelleBSOffice.ttf) format('truetype');
}

.search {
  max-width: 1700px;
  margin: auto;
  margin-top: 75px;
  left: 1%;
  right: 1%;
  position: absolute;
}

.governance {
  max-width: 1100px;
  margin: auto;
  left: 5%;
  position: absolute;
}

.min-margin-container {
  /* margin: 0px 10px 0px 10px; */
  margin: 0px 20px;
  padding-bottom: 2.5rem; /* Footer height */
}
.main-container {
  width: 800px;
  margin: auto;
}

.inputField-root {
  display: flex;
  margin-right: -8px;
}

.transparent {
  background-color: rgba(255, 255, 255, 0.5); /* Adjust the opacity here */
  opacity: 0.3; /* Adjust the overall component opacity */
}

.inputField {
  flex: 1;
  margin-right: 8px;
}

.inputFieldClick-root {
  margin-right: -8px;
  cursor: 'pointer';
}

.inputFieldClick {
  margin-right: 8px;
  cursor: 'pointer';
}

.mainViewUser-container {
  width: 720px;
  margin: auto;
}
.secondViewUser-container {
  width: 1000px;
  margin: auto;
}

.tooltip {
  visibility: hidden;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease-out;
  will-change: opacity, visibility;
}

.floating-container .floating-label {
  opacity: 0;
  transition: opacity 0.6s ease;
}

.floating-container:hover .floating-label {
  opacity: 1;
  cursor: pointer;
}

.flash {
  animation: flash-animation 0.5s linear infinite;
  border: 1px solid transparent; /* Add a border to make the flashing more visible */
  border-radius: 1px; /* Add border-radius for rounded corners */
  box-shadow: 1px 1px 1px #ffc400; /* Add a yellow box shadow for extra emphasis */
  background-color: #3f3e3b;
}

/* Define the animation keyframes */
@keyframes flashing {
  0% {
    box-shadow: 0 0 0.5px #ffc400;
  } /* Red glow */
  50% {
    box-shadow: 0 0 7px #ffc400;
  } /* Larger red glow */
  100% {
    box-shadow: 0 0 0.5px #ffc400;
  } /* Red glow */
}

/* Apply the animation to the textbox with the .flash class */
.flash {
  animation: flashing 1s infinite; /* Adjust the duration as needed */
  background-color: #ffc400;
}
/* NAVBAR */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e0e0e0;
  height: 50px;
  margin-bottom: 40px;
  background-color: #e0e0e0;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center; /* Center list items vertically */
  flex: 1; /* Allow the list to take the available space between the image and button */
  display: flex;
  justify-content: center; /* Horizontally center the list items */
}

.nav-links li {
  margin-right: 5px;
}

.nav-links li button {
  color: #555;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-size: 15px;
  text-transform: none;
}

.nav-links li button:hover {
  color: white;
  background-color: #555;
}
.nav-linksOn {
  color: white !important;
  background-color: #555 !important;
}

.movable {
  color: #ffffff;
  text-align: center;
  line-height: 150px;
  cursor: grab;
  position: absolute;
  user-select: none;
  transition: transform 0.2s ease-out;
}

.movable.dragging {
  cursor: grabbing;
}

.pointer {
  cursor: pointer;
}

.moveElementToCenter {
  display: flex;
  justify-content: center;
}

.infobox {
  border: 2px solid #e0e0e0;
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  margin-left: 10px;
  width: 250px; /* No quotes around the value */
  height: 350px; /* No quotes around the value */
  position: fixed;
  top: 0;
  right: 15px;
}

.infobox h2 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}

.infobox p {
  font-size: 1em;
  color: #555;
}

.MUIDataTableHeadCell-root-5 {
  width: 2001px; /* Change this value to your desired width */
}

.MUIDataTableBodyCell-root-5 {
  width: 2001px; /* Change this value to your desired width */
}
